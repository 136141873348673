import React from "react";

export type HomeSectionPayoutSystemProps = {};

type HomeSectionPayoutSystemState = {
  isLoading: boolean;
};

export class HomeSectionPayoutSystem extends React.Component<HomeSectionPayoutSystemProps, HomeSectionPayoutSystemState> {
  state: HomeSectionPayoutSystemState = {
    isLoading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionPayoutSystemProps, prevState: HomeSectionPayoutSystemState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <section className="section section-features bg-theme tc-light">
          <div className="container">
            <div className="nk-block nk-block-lg nk-block-features-s2">
              <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                <div className="col-md-6">
                  <div className="gfx ">
                    <img src="images/gfx/gfx-s-light.png" alt="gfx" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nk-block-text">
                    <h2 className="title ">Tokenization Benefits</h2>
                    <p>
                      The standard payout ratio for all Funded Trader Live Accounts at BoneX Fund Trader is set at 80:20, with the trader receiving 80% of the profits and Bonex
                      Fund Trader retaining 20%.
                    </p>
                    <p>
                      Funded traders can request payouts directly through their dashboard, opting for cryptocurrency withdrawals to their preferred wallet. We ensure that your
                      withdrawals are processed promptly and securely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
