
export enum CentralServerPathParamKind {
    fundTradingProgramStageId = "{fundTradingProgramStageId}",
    fundTradingProgramId = "{fundTradingProgramId}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // auth
    auth_sign_in = "auth/sign-in",
    auth_sign_up = "auth/sign-up",
    auth_request_password_reset = "auth/request-password-reset",
    auth_reset_password = "auth/reset-password",
    auth_accept_invitation = "auth/accept-invitation",

    // profile
    profile_change_password = "profile/change-password",

    // fund-trading-program
    fund_trading_programs = "fund-trading/programs",
}
