import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionFundingSpecification } from "../components/home/HomeSectionFundingSpecification";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";

export type HomePageProps = {};

type HomePageState = {};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomePageProps, prevState: HomePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="">
              <div className="header-banner bg-theme-dark has-ovm has-mask">
                <div className="nk-banner">
                  <div className="banner banner-fs banner-single banner-gap-b2" style={{ padding: "0px" }}>
                    <div className="banner-wrap">
                      <div className="container" style={{marginTop: "60px"}}>
                        <div className="row align-items-center justify-content-center">
                          <div className="col-lg-5 order-lg-last">
                            <div className="banner-gfx banner-gfx-re-s1 ">
                              <img src="images/blue-phoenix-image.png" alt="header" style={{
                                width: "53%",
                                position: "relative",
                                left: "126px"
                              }} />
                              {/* <img src="images/blue-phoenix.gif" alt="header" /> */}
                            </div>
                          </div>
                          <div className="col-lg-7 col-sm-10 text-center text-lg-start">
                            <div className="banner-caption cpn tc-light">
                              <div className="cpn-head">
                                <h1 className="title" style={{ textTransform: "uppercase" }}>
                                  You trade, we fund
                                </h1>
                              </div>
                              <div className="cpn-text">
                                <p>
                                  BoneX Fund Trader is a specialized evaluation services firm focused exclusively on the cryptocurrency sector. We provide traders with the
                                  opportunity to trade various cryptocurrencies using virtual funds, while also allowing them to generate real profits. Our primary goal is to
                                  assess traders' skills and support their professional development.
                                </p>
                                <p>
                                  To participate in our program, traders are required to successfully complete an examination process. Upon passing, you will be granted access to
                                  up to $200,000 in virtual funds and will be eligible to receive 80% of the profits you earn.
                                </p>
                              </div>
                              <div className="cpn-action">
                                <div className="cpn-btns ">
                                  <a className="btn btn-grad" href="/register">
                                    Register now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="nk-ovm mask-a-dark shape-a"></div> */}
                <div id="particles-bg" className="particles-container particles-bg">
                  <canvas className="particles-js-canvas-el" width={2543} height={751} style={{ width: "100%", height: "100%" }} />
                </div>

              </div>
            </section>

            <HomeSectionProgram />

            <HomeSectionFundingSpecification />

            <HomeSectionPayoutSystem />

            <HomeSectionCalculator />

            <HomeSectionFaq />
          </main>
        </Layout>
      </React.Fragment>
    );
  }
}
