import React from "react";

export type PreloaderProps = {};

type PreloaderState = {
  loading: boolean;
};

export class Preloader extends React.Component<PreloaderProps, PreloaderState> {
  state: PreloaderState = {
    loading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // Remove preloader after 1 second
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  componentDidUpdate = async (prevProps: PreloaderProps, prevState: PreloaderState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="preloader preloader-alt no-split">
            <span className="spinner spinner-round"></span>
          </div>
        )}
      </React.Fragment>
    );
  }
}
