import React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppProvider";
import { ProfileMenu } from ".//ProfileMenu";

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  search: boolean;
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state = {
    megaMenu: false,
    search: false
  };

  // #region State Setters
  setMegaMenu(value: boolean) {
    this.setState((prevState) => {
      return { megaMenu: value };
    });
  }

  setSearch(value: boolean) {
    this.setState((prevState) => {
      return { search: value };
    });
  }
  // #endregion

  // #region handlers
  handleToggleLeftMenu() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collapsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  handleSubmitSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  // #endregion

  render() {
    return (
      <React.Fragment>
        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed" id="header">
          <div className="header-main">
            <div className="header-container container">
              <div className="header-wrap">
                <div className="header-logo logo">
                  <a href="./" className="logo-link">
                    <img className="logo-dark" src="/images/logo/logo-color.svg" srcSet="images/logo2x.png 2x" alt="logo" />
                    <img className="logo-light" src="/images/logo/logo-color.svg" srcSet="images/logo-full-white2x.png 2x" alt="logo" />
                  </a>
                </div>
                <div className="header-nav-toggle">
                  <a href="#" className="navbar-toggle" data-menu-toggle="header-menu">
                    <div className="toggle-line">
                      <span />
                    </div>
                  </a>
                </div>
                <div className="header-navbar">
                  <nav className="header-menu" id="header-menu">
                    <ul className="menu">
                      {/* <li className="menu-item">
                        <a className="menu-link nav-link" href="#">
                          How It Works
                        </a>
                      </li> */}
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#token">
                          Programs
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#calculator">
                          Calculator
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#faq">
                          FAQ
                        </a>
                      </li>
                    </ul>
                    <ul className="menu-btns">
                      <li>
                        <a href="https://app.bonex.fund" className="btn btn-md btn-auto btn-grad no-change">
                          <span>App</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
