import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionFundingSpecification } from "../components/home/HomeSectionFundingSpecification";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";

export type TermsOfConditionsPageProps = {};

type TermsOfConditionsPageState = {};

export class TermsOfConditionsPage extends React.Component<TermsOfConditionsPageProps, TermsOfConditionsPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: TermsOfConditionsPageProps, prevState: TermsOfConditionsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-white" id="solution">
              <div className="container" style={{
                border: "1px solid red",
                borderRadius: "20px",
                padding: "30px 80px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title">Terms of Conditions</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>
                <p>
                  Welcome to BoneX Fund Trading!
                </p>
                <p>
                  At BoneX Fund Trading, we focus on two things: financial education of traders and enabling them to make profits as a result of such training. These TERMS AND CONDITIONS (“Terms”) set out the rights and obligations of all users (“You”) in relation to the use of this website [Company Name] (“Company”, “our”, or “us”) domiciled at [Company Address]. By using any of our services on the Company’s Platform, you declare and warrant that you have the authority to bind yourself to these Terms. If you do not agree with these terms, you will not be able to access or use our services.
                </p>
                <p>
                  Please read the terms completely and carefully.
                </p>

                <h3>1. Our Platform</h3>
                <p>
                  <h4>1.1 BoneX Fund Trading Services</h4>
                  <p>1.1.1. We provide a platform and tools for training and education in simulated trading in the forex and cryptocurrency market or trading with other instruments in other financial markets. Accordingly, we offer an opportunity for traders to train and apply their financial knowledge by operating from demo accounts.</p>
                  <p>1.1.2. You must first pass our ONE or TWO phases evaluation program before you can start trading for real profits, based on the knowledge acquired, through our rewards in the form of scholarships.</p>
                  <p>1.1.3. In each of our phases, you will have continuous assistance from our team of tutors, who will accompany you 24 hours a day, as well as various materials and explanatory videos that provide you with the necessary training.</p>
                  <p>1.1.4. Successfully training in our phase evaluation program will give you the option to trade in a final phase where, if your performance is positive, you will obtain a diploma and an economic reward according to your proven worth.</p>
                  <p>1.1.5. You acknowledge that any operation you perform through our service is not real, based on a mere simulated environment that facilitates your training.</p>
                  <p>1.1.6. By using our services, you agree to be governed by our Trading Objectives.</p>

                  <h4>1.2 Third Party Services</h4>
                  <p>1.2.1. You acknowledge and accept that the Company provides access to third-party applications.</p>
                  <p>1.2.2. You may connect your account to third-party accounts. By connecting your account to the third-party account, you acknowledge and accept that you are consenting to the ongoing disclosure of information about you to third parties (in accordance with your privacy settings on those third-party sites). If you do not want information about you to be shared in this way, do not use this feature.</p>

                  <h4>1.3 Modifications to the Conditions</h4>
                  <p>1.3.1 The Company may modify these Conditions or any Policy at any time at its sole discretion.</p>
                  <p>1.3.2 You are responsible for reviewing such updates and any applicable changes.</p>
                  <p>1.3.3 Your continued participation in the Platform, the use of any of the Company’s Services, or the use of any of the Company’s Tools constitutes your acceptance of such changes.</p>
                  <p>1.3.4 If you do not agree with any of the posted changes, you must immediately cease using the Company’s Platform, the Company’s Services, or the Company’s Tools.</p>

                  <h4>1.4 Privacy</h4>
                  <p>Your use of bonexfundtrading.com is subject to the terms of our Privacy Policy. Please review our Privacy Policy, which also governs the site and informs users of our data collection practices.</p>

                  <h4>1.5 Electronic Communication</h4>
                  <p>Visiting bonexfundtrading.com or sending us emails constitutes electronic communications. You consent to receive electronic communications and agree that all agreements, notices, disclosures, and other communications that we provide to you electronically, via email, and on the Site, satisfy any legal requirement that such communications be in writing.</p>

                </p>
                <h3>2. Accessibility</h3>
                <p>
                  <p>
                    You understand and admit that occasionally the Company’s platform may be inaccessible or inoperable for any reason, including, without limitation, due to:
                  </p>
                  <p>
                    i. equipment malfunctions; ii. periodic maintenance procedures or repairs that the Company may carry out occasionally; or iii. causes beyond the Company’s control or that are not reasonably foreseeable by the Company.
                  </p>
                </p>
                <h3>3. Account Management</h3>
                <p>
                  <h4>3.1 Account Registration</h4>
                  <p>3.1.1 When you purchase one of our training packages, you will receive credentials to trade on the BoneX Fund Trading platform or the MetaTrader 5 platform.</p>
                  <p>3.1.2 You may not share your password with anyone except authorized employees.</p>
                  <p>3.1.3 You must always provide accurate, current, and complete information, and must update such information in a timely manner to maintain its accuracy and completeness. Providing false information of any kind will result in the cancellation or rejection of your account.</p>
                  <p>3.1.4 Any use of the site through your account will be deemed to have been used by you.</p>
                  <p>3.1.5 In all cases of our service you will only get DEMO account credentials, proper to our training program.</p>
                  <p>3.1.6 The Company has the right to rely on the contact and other information provided to us through your Account.</p>
                  <p>3.1.7 Your Account is NON-TRANSFERABLE and NON-ASSIGNABLE.</p>
                  <p>3.1.8 You have the right to cancel your registration.</p>
                  <p>3.1.9 We are not responsible for third-party access to and from your Account resulting from theft or misappropriation of your account.</p>
                  <p>3.1.10 We and our associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content at our sole discretion, without providing any reason and with immediate effect.</p>
                  <p>3.1.11 Upon signing the contract to receive payment for your good training, the client will have to fill out a mandatory KYC form before receiving the returns from the scholarships.</p>

                  <h4>3.2 Setup</h4>
                  <p>3.2.1 Once your registration is complete, you can set up your account for our evaluation process in one or two phases.</p>
                  <p>3.2.2 After you place your order and have paid our training fees, we will email your credentials within minutes to 48 hours for the trading platform you have chosen.</p>
                  <p>3.2.3 BoneX Fund Trading has disabled free training trials until further notice.</p>
                  <p>3.2.4 It is strictly prohibited to lend or receive services from/for third parties of any kind, whether to boost accounts, offer benefits in exchange for accounts, profits, or any benefit from the site. This will be penalized with the suspension of all active accounts of both parties.</p>

                </p>
                <h3> 4. Non-Permitted Usage / Usage by Minors</h3>
                <p>
                  Subject to the age of majority in your jurisdiction, generally, the Company’s platform is for people over 18 years old. If you are under 18 years old, you are not permitted to use this site.
                </p>
                <h3>5. Fees and Other Payments</h3>
                <p>

                  <p>5.1 You must pay a registration fee to access the training course before using our service. The registration fee is paid to access our Evaluation Process, in any of its modalities. You will not be entitled to any diploma, reward, or refund if you do not fully complete the phases of our training process.</p>


                  <p>5.2 The funds provided to you during Phase 1, Phase 2, Phase 3, or ‘Funded’ phase are DEMO funds. These funds are not real funds and cannot be used for real operations. The profits you generate through our “Phase 1”, and/or “Phase 2” level are not real profits, you are not entitled to those profits, nor can you request a withdrawal during this period; regardless of whether you have opted for the evaluation of 1 or 2 phases. In the ‘funded’ phase of the evaluation, these benefits are still not real, although in this case they may involve a reward in the form of a scholarship as specified in section 1.1.</p>


                  <p>5.3 The fees for our services are denominated in DOLLARS. In case of payment of the fees in any currency other than DOLLAR, the amount of the fees corresponding to the selected option will be converted to DOLLARS using the exchange rate of your payment processor. You acknowledge that, if payment is made in a currency different from the one you have chosen on the website, the amount will be converted according to the exchange rates in force at the time of payment.</p>


                  <p>5.4 Service charges include the corresponding taxes.</p>


                  <p>5.5 You can pay the fees for our “Training Process” using a payment card or cryptocurrencies. Payment will be made immediately.</p>

                  <p>5.6 of fees for the BoneX Fund Trading Evaluation Process
                    <h5>5.6.1 Two Phases:</h5>
                    <ul>
                      <li>60 USD for the Evaluation Process with an initial demo capital of 5,000 USD</li>
                      <li>115 USD for the Evaluation Process with an initial demo capital of 10,000 USD</li>
                      <li>220 USD for the Evaluation Process with an initial demo capital of 25,000 USD</li>
                      <li>325 USD for the Evaluation Process with an initial demo capital of 50,000 USD</li>
                      <li>560 USD for the Evaluation Process with an initial demo capital of 100,000 USD</li>
                      <li>1,090 USD for the Evaluation Process with an initial demo capital of 200,000 USD</li>
                    </ul>
                    <h5>5.6.2 One Phase:</h5>
                    <ul>
                      <li>59 USD for the Evaluation Process with an initial demo capital of 5,000 USD</li>
                      <li>115 USD for the Evaluation Process with an initial demo capital of 10,000 USD</li>
                      <li>249 USD for the Evaluation Process with an initial demo capital of 25,000 USD</li>
                      <li>379 USD for the Evaluation Process with an initial demo capital of 50,000 USD</li>
                      <li>599 USD for the Evaluation Process with an initial demo capital of 100,000 USD</li>
                      <li>1,149 USD for the Evaluation Process with an initial demo capital of 200,000 USD</li>
                    </ul>
                  </p>
                </p>
                <h3>6. Rules of Demo Trading</h3>
                <p>
                  <p>
                    During demo trading on the Trading Platform, you may carry out any transaction, unless they constitute prohibited trading strategies or operations as defined in Section 7. Restrictions may also be imposed by the conditions of the Trading Platform you have selected.
                  </p>
                  <p>
                    You acknowledge that the Provider has access to information about the demo operations you perform on the Trading Platform. You authorize the Provider to share this information with persons/entities that are part of a group with the Provider or that are otherwise affiliated with the Provider, and you grant the Provider and these persons/entities your consent and authorization to handle this information at their free will. You agree that these activities are carried out automatically without any kind of consent, consultation, or approval on your part and that you have no right to any compensation or income associated with the use of this information.
                  </p>
                  <p>
                    The Provider is aware that you do not provide any investment advice or recommendation through your demo operations. You acknowledge that you may suspend your demo operations on the Trading Platform at any time.
                  </p>
                  <p>
                    You are aware that there may be updates/modifications including or removing new Liquidity or Data Feed Providers as the company deems appropriate at any time, without the obligation of prior notice.
                  </p>
                  <p>
                    The conditions of slippage, spread, or available platforms may vary depending on the company’s new partnerships as well as new Data Feed or Liquidity Providers or the integration or suspension of new platforms.
                  </p>
                  <p>
                    Slippage and spread depend entirely on the liquidity or data feed provider. In no case do we add markups, spread, or fictitious slippage to the provider’s price.
                  </p>
                  <p>
                    The profits generated by the user during the account lock period in case of not closing the trades after requesting a withdrawal will not be valid and may be withdrawn by the team.
                  </p>
                  <p>
                    The BoneX Fund Trading team reserves the right to decide whether a user is eligible to reset their funded account regardless of the requirements established in the FAQ. The team may choose a Reset limit at their discretion.
                  </p>
                  <p>
                    At this time, leverage would be as follows:
                    <h4>ADVANCED ACCOUNTS:</h4>
                    <ul>
                      <li>Forex; leverage limited to 1:100</li>
                      <li>Commodities; leverage limited to 1:100</li>
                      <li>Indices; leverage limited to 1:100</li>
                      <li>Cryptos; leverage limited to 1:100</li>
                      <li>Stocks; leverage limited to 1:100</li>
                    </ul>
                    <h4>STUDENT ACCOUNTS:</h4>
                    <ul>
                      <li>Forex; leverage limited to 1:50</li>
                      <li>Commodities; leverage limited to 1:50</li>
                      <li>Indices; leverage limited to 1:50</li>
                      <li>Cryptos; leverage limited to 1:50</li>
                      <li>Stocks; leverage limited to 1:50</li>
                    </ul>
                  </p>
                </p>
                <h3>7. Prohibited Trading Strategies or Transactions</h3>
                <p>
                  <h4>7.1 DURING THE EVALUATION OPERATIONS AND “FUNDED TRADER”, IT IS STRICTLY PROHIBITED TO CARRY OUT ANY OF THE ACTIONS MENTIONED BELOW:</h4>
                  <ol style={{listStyle: "lower-roman"}}>
                    <li>It is expressly prohibited to use the Services to violate any law, statute, ordinance, regulation, or treaty, whether local, state, provincial, national, or international, or to infringe the rights of a third party, including, among others, intellectual property rights, privacy rights, publicity rights, or other personal or property rights.</li>
                    <li>In addition, it is expressly prohibited to scrape, crawl, frame, post unauthorized links, aggregate, hack, carry out denial-of-service attacks, reverse engineer, or circumvent the technological protection measures of the Services or the Company’s website.</li>
                    <li>It is also prohibited to use the Services or the Company’s website to transmit unsolicited commercial products or services to third parties or to Company Merchants. Although the Company is not responsible for any content posted by its Merchants and has no obligation to monitor such content, it reserves the right to remove any such content from any company communication channel.</li>
                    <li>You are also prohibited from using any trading strategy that is expressly prohibited by our Data Feed or Liquidity Providers, which will include, but not be limited to:
                      <ul>
                        <li>Taking advantage of errors or latency in pricing and/or platform(s) provided.</li>
                        <li>Using non-public and/or privileged information.</li>
                        <li>Using any third-party strategy, a strategy marketed to overcome the requirements of the valuation or evaluation phase.</li>
                        <li>Manipulating operations for fraudulent purposes.</li>
                      </ul>
                    </li>
                    <li>Attempting to arbitrage your account with another Company or third-party account, as determined by the Company at its sole and absolute discretion.</li>
                    <li>The use of trading strategies that are difficult to replicate in the real market, or come with a higher risk when trying to do so. This includes, but is not limited to: operations that use all the available margin for individual positions, operations that result in excessively large fluctuations in unrealized gains/losses, etc.</li>
                    <li>Executing trade ideas that belong to or are inspired by third parties, including, among other things, copying ideas from other traders and analysts and copying trade signals from any type of trading community, social networks, research reports, crowdsourcing ideas, etc.</li>
                    <li>Sharing accounts of any nature, including but not limited to sharing credentials with other users and/or operating multiple accounts from the same household, devices, or IP address/VPS. The user is responsible for the risk of using VPNs, as these could lead to IP matches with other users.</li>
                    <li>The Trader declares, warrants, and agrees not to make any public statement in any type of social media or public access medium disputing, challenging, disputing, or criticizing in any way the application by the BoneX Fund Trading Account.</li>
                    <li>A contradiction with the Terms and Conditions of the Provider.</li>
                    <li>Purpose that contradicts the way trading is carried out in the real market or in a manner that the Provider may suffer losses or damages as a result of the Client’s activity.</li>
                    <li>Exceeding the maximum capital allocation allowed as determined in the website’s FAQ.</li>
                    <li>Obtain all the profit in a single trade. Once the capital reaches $10,000 of profit per day and/or per trade, active trades will be closed and the amount that exceeds $10,000 will be deducted. The difference will be calculated based on the balance at 12:05 AM UTC. Any operation closed in several parts or several operations executed in similar time counts as a single operation.</li>
                    <li>Hedging or reverse trading.</li>
                    <li>We are a trading training company, so we do not allow in any case the non-investment, i.e., gambling; being the company’s choice the right to decide on such consideration.</li>
                    <li>The use of the platforms as well as resets or migrations in MT5 and CFT Platform to all new users belonging to the United States is restricted.</li>
                    <li>Any disrespect by the user to the Staff or to the BoneX Fund Trading company through any communication channel will result in the indefinite suspension of the user.</li>
                  </ol>
                  <p>If the Company determines, at its discretion, that your operations constitute the aforementioned Prohibited Operations, or that they violate the Risk Management and Program Rules, or that your public statements violate the Non-Discrimination Clause, your participation will be terminated, which may include the loss of the established returns.</p>

                  <h4>7.2 If you engage in any of the practices described in section 7.1:</h4>
                  <ol style={{listStyle: "lower-roman"}}>
                    <li>The company may consider it a breach of the conditions of the particular Evaluation Process if you carry out any of the practices as a “Funded Trader”. The Provider may consider it a violation of our rules and will remove the Funded Account provided to you, and you will be expelled from the training program.</li>
                    <li>If you have carried out any of the transactions listed in section 7.1, the Provider reserves the right to suspend the account without prior notice. In such a case, you will not be entitled to the Profit Sharing or the refund of the fees paid.</li>
                  </ol>
                </p>
                <h3>8. BoneX Fund Trading Evaluation Process</h3>
                <p>
                  <p>In order for you to meet our conditions of the Evaluation Process, you must fulfill all of the following parameters at the same time by the end of the trading period:</p>
                  <ol type="i" style={{listStyle: "lower-roman"}}>
                    <li>At no time during the ‘2 Phases Evaluation’ Process have you registered losses in any open or closed demo trades on the same day, which exceeded in total 5% of your balance at 12:05 AM UTC or 10% of the initial demo balance.</li>
                    <li>At no time during the ‘1 Phase Evaluation’ Process have you registered losses in any open or closed demo trades on the same day, which exceeded in total 4% of your balance at 12:05 AM UTC or 6% based on the maximum demo balance until you make a 6% profit, at which point your trailing drawdown will remain static at the account’s opening balance.</li>
                    <li>If you have reached the Profit Goal of 8% of the initial capital in Phase 1 or the Profit Goal of 5% of the initial capital in Phase 2, in the 2-phase evaluation; or 10% in the 1-phase evaluation; or the Profit target of 5% of the initial capital in both Phase 1, Phase 2 and Phase 3 of the 3-phase evaluation and have not violated the terms, particularly the rules of demo trading under Section 7, the Provider will assess your current Phase in our Service as successful.</li>
                    <li>In the evaluation of 0 phases the particular conditions are the following: the total loss limit is limited to 6% of the account; in the same way, only a loss of 4% of the account is allowed on a daily basis. Likewise, in order to scale, 10% of the account must be exceeded, withdrawing at the same time the amount generated in the scholarship without the obligation of having a minimum number of days traded. You can withdraw before reaching the 10% if you have traded a minimum of 15 days or 30 calendar days as stipulated on the website.</li>
                  </ol>
                  <p>The rest of the conditions are subject to the description of what is indicated on the bonexfundtrading.com website.</p>

                </p>
                <h3>9. BoneX Fund Trading ‘Funded Trader’</h3>
                <p>
                  <p>
                    In case the Client successfully completes our two phases of the Evaluation Process, the Client must sign an individual agreement signed between the Client and [Company Name].
                  </p>
                  <h4>9.1 ‘Funded Trader’ Copy Trading</h4>
                  <p>Once we have confirmed that our funded trader is profitable according to our criteria, we can move on to include the conditions set by the current provider at the time we want to do copy trading.
                  </p>
                </p>
                <h3>10. Ownership of Information</h3>
                <p>
                  <p>
                    You retain all rights and interests in all information you provide on the Platform.
                  </p>
                  <p>
                    You grant the Company permission to use all content for advertising the Company’s platform and for the Company.
                  </p>
                  <p>
                    The Company will NOT sell your data to third parties.
                  </p>
                </p>
                <h3>11. Duration</h3>
                <p>
                  The rights and obligations arising from these conditions will last as long as the user’s active contracts, normally automatically renewed each year.
                </p>
                <h3>12. Disputes</h3>
                <p>
                  The parties are encouraged to attempt to resolve disputes amicably. If for any reason this fails, the Courts of Zug (Switzerland) are established as the dispute forum, with express waiver of any other jurisdiction.
                </p>
                <h3>13. Limitation of Liability and Indemnification</h3>
                <p>
                  <p><strong>13.1</strong> You will use the platform at your own risk. BoneX Fund Trading is not responsible for losses caused to the user in cases of force majeure that cannot be controlled by the company and do not include an error on our part.</p>

                  <p><strong>13.2</strong> The information, software, products, and services included or available through the site may include inaccuracies or typographical errors. Changes are periodically added to the information herein.</p>

                  <p><strong>13.3</strong> BoneX Fund Trading and/or its suppliers may make improvements and/or changes in the site at any time, reserving the right to migrate users’ accounts from one available platform to another when the company requires such action due to force majeure or the sustainability of the same.</p>

                  <p><strong>13.4</strong> Neither BoneX Fund Trading nor its tutors, agents, directors, shareholders, members, partners, managers, officers, employees, information providers, attorneys, or affiliates shall have any liability to you and hereby waive, release, and agree not to sue any of them, any claim for any special, indirect, incidental, or consequential damages suffered or incurred by you in connection with, arising out of, or in any way related to this agreement or with the use by you of any of our services, even if we have been advised of the possibility of such damages occurring. If you are not satisfied with the platform or the services, or with any content of the same your sole and exclusive remedy is to cease using the platform and the services, as used herein.</p>

                  <p><strong>13.5</strong> This limitation applies to the extent permitted by law.</p>

                  <p><strong>13.6</strong> You agree: To indemnify, defend, and hold harmless us, our tutors, directors, employees, agents, and third parties, for any loss, cost, liability, and expense (including reasonable attorneys’ fees) related to or arising from your use or inability to use the Site or the services, any user postings made by you, your violation of any terms of this Site or the services by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules, or regulations. We reserve the right, at our cost, to assume the exclusive defense and control of any matter subject to indemnification by you, in which case you will cooperate fully with us in asserting any available defenses.</p>
                </p>
                <h3>14. Disclaimer</h3>
                <p>
                  <p><strong>14.1</strong> BoneX Fund Trading is a provider of online financial education services. Accordingly, we do not offer financial services, nor financial, tax, brokerage, or other advice. You also acknowledge that we do not accept any deposits. We only charge fees for the training carried out, for the settlement of costs and/or operational expenses of personnel and technology, for the proper functioning of our services.</p>

                  <p><strong>14.2</strong> [Company Name] does not provide any of the investment services listed in the capital markets law and only uses a demo version of investment scenarios, so it does not guarantee the payment of the paid scholarship to the trader. The parties agree that [Company Name] may, under certain circumstances, not pay the paid scholarship despite the trader’s achievement, waiving any subsequent claim. All this on the basis that it is a training program that experiments on investment scenarios in demo mode and that the main motivation of the participants is the perception of financial training and not the obtaining of the possible scholarship. In the event that, for whatever reason, such a scholarship could not be integrated into the trader, he/she will have the right only and exclusively to the return of the fees paid for participation in the program, exempting [Company Name] from the payment of the remuneration.</p>

                  <p><strong>14.3</strong> The content of our platform is applicable to the extent that local laws and/or regulations allow.</p>

                  <p><strong>14.4</strong> All information on the platform is provided “as is”, without warranty of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness for a particular purpose and fitness for a particular purpose.</p>

                </p>
                <h3>15. Intellectual Property</h3>
                <p>
                  <p><strong>15.1</strong> The Company’s Services and the content of the Platform (excluding Content provided by You or other users), features, and functionality are and will remain the exclusive property of the Company and its licensors.</p>

                  <p><strong>15.2</strong> The Company’s trademarks may not be used in connection with any product or service without the prior written consent of the Company.</p>

                  <p><strong>15.3</strong> The Company is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>

                  <p><strong>15.4</strong> The Company grants You a temporary and non-exclusive license to use such services, including third-party services, on the Platform to the limited extent of facilitating this Agreement.</p>

                  <p><strong>15.5</strong> The Company also owns all transaction information and any other information related to orders, including, among others, the information that is created after a transaction, and the ratings and reviews provided by the Clients. All this information is subject to any existing Privacy Policy on the Company’s Platform.</p>
                </p>

                <h3>16. Applicable Law</h3>
                <p>
                  <p><strong>16.1</strong> The Service is controlled, operated, and administered by the Company from our offices. To the fullest extent permitted by law, this agreement is governed by the laws of Switzerland, and hereby you consent to the exclusive jurisdiction and venue of courts in Switzerland (canton of Zug), in all disputes arising out of or relating to the use of the Site. The use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

                  <p><strong>16.2</strong> If you access the Site from anywhere in the world, you will be responsible for compliance with all local laws. You agree that you will not use our Content accessed through bonexfundtrading.com in any country or in any manner prohibited by applicable laws, restrictions, or regulations.</p>
                </p>
                <h3>17. Termination</h3>
                <p>
                  <p><strong>17.1</strong> We reserve the right, at our sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice, being able to claim only the return of the payment for the training account in case it was still active at that time.</p>

                  <p><strong>17.2</strong> You will continue to be bound by these terms, even if your account is cancelled. Specifically, any of your obligations related to the limitations of liability, and indemnification, as well as any other obligation under this Agreement that by its nature are intended to survive, including any obligation of payment or services, will survive the termination of this Agreement.</p>

                  <p><strong>17.3</strong> The termination of this Agreement shall be without prejudice to the rights of either Party against the other in connection with any claim prior to or breach of any of the provisions of this Agreement.</p>

                </p>
                <h3>18. Entirety</h3>
                <p>These terms and the Privacy Policy represent the entire Agreement between both parties and supersede any prior written or oral agreement.</p>
                {/* <h3>Contact Us</h3>
                <p>
                  If you have any questions or concerns about any of these terms, do not hesitate to contact us.
                  Our contact: support@bonexfundtrading.com
                </p> */}
              </div>
            </section>


          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
