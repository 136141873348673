import React from "react";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";

export type HomeSectionProgramInfoProps = {
  dataFundTradingProgramListList: FundTradingProgramDto[];
};

type HomeSectionProgramInfoState = {
  phases: number;
};

export class HomeSectionProgramInfo extends React.Component<HomeSectionProgramInfoProps, HomeSectionProgramInfoState> {
  state: HomeSectionProgramInfoState = {
    phases: 1
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionProgramInfoProps, prevState: HomeSectionProgramInfoState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleButtonPhaseClick = async (e: React.FormEvent<HTMLButtonElement>, phases: number) => {
    e.preventDefault();
    this.setState({ phases });
  };

  handleButtonStartClick = async (e: React.FormEvent<HTMLButtonElement>, item: FundTradingProgramDto) => {
    e.preventDefault();

    console.log("clicked", item);
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    const with_1_phase: FundTradingProgramDto[] = this.props.dataFundTradingProgramListList.filter((ele) => ele.numberStages == 1).sort((a, b) => a.accountSize - b.accountSize);
    const with_2_phases: FundTradingProgramDto[] = this.props.dataFundTradingProgramListList.filter((ele) => ele.numberStages == 2).sort((a, b) => a.accountSize - b.accountSize);

    const list: FundTradingProgramDto[] = this.state.phases == 1 ? with_1_phase : with_2_phases;

    console.log("test", this.props)
    // console.log(this.props)
    // console.log(this.props)
    // {item.fundTradingProgramStages[0].minTotalDays}
    return (
      <React.Fragment>
        <section className="section section-features bg-light-alt" id="token">
          <div className="container">
            <div className="section-head text-center wide-auto">
              <h2 className="title">Get funded now</h2>
              <p>Test your skills through our challenges and get up to $200,000 of demo funds to trade and gain real profits.</p>
            </div>

            <div className="tab-s1 text-center">
              <ul
                className="nav tab-nav-s4 text-center bg-theme-grad animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".2"
                role="tablist"
                style={{ visibility: "visible", animationDelay: "0.2s" }}
              >
                <li>
                  <a
                    data-bs-toggle="tab"
                    // href="#tab-dist-1"
                    aria-selected="true"
                    role="tab"
                    className={`${this.state.phases === 1 ? "active" : "show"}`}
                  >
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        color: this.state.phases === 1 ? "red" : "white"
                        
                      }}
                      onClick={(e) => this.handleButtonPhaseClick(e, 1)}
                    >
                      Phase 1{" "}

                    </button>
                    {/* Distribution{" "} */}
                  </a>
                </li>
                <li>
                  <a
                    className={`${this.state.phases === 2 ? "active" : "show"}`}
                    // className="show"
                    data-bs-toggle="tab"
                    // href="#tab-fund-1"
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        color: this.state.phases === 2 ? "red" : "white"
                      }}
                      onClick={(e) => this.handleButtonPhaseClick(e, 2)}
                    >
                      Phase 2
                    </button>
                  </a>
                </li>
              </ul>

            </div>

            <div className="row pdb-l">
              <div className="col text-center">
                {/* <ul className="btn-grp gutter-20px">
                  <li>
                    <button className={`btn ${this.state.phases === 1 ? "btn-primary" : ""}`} onClick={(e) => this.handleButtonPhaseClick(e, 1)}>
                      1 Phase
                    </button>
                  </li>
                  <li>
                    <button className={`btn ${this.state.phases === 2 ? "btn-primary" : ""}`} onClick={(e) => this.handleButtonPhaseClick(e, 2)}>
                      2 Phases
                    </button>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="nk-block nk-block-token">
              <div className="row gutter-vr-30px">
                <div className="col">
                  <div className="row">
                    {(list || []).map((item: FundTradingProgramDto, key: number) => (
                      <div className="col" key={key}>
                        <div className="token-stage text-center ">
                          <div className="token-stage-title token-stage-pre">{item.title}</div>
                          <div className="token-stage-date">
                            <span>Account Size</span>
                            <h6>{Number(item.accountSize).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h6>
                          </div>
                          <div className="token-stage-info">
                            <span className="token-stage-bonus">{item.fundTradingProgramStages[0].maxDailyLoss} %</span>
                            <span className="token-stage-cap">Max daily loss</span>
                          </div>

                          {item.fundTradingProgramStages[0].maxTotalLoss && (
                            <div className="token-stage-info">
                              <span className="token-stage-bonus">{item.fundTradingProgramStages[0].maxTotalLoss} %</span>
                              <span className="token-stage-cap">Max loss</span>
                            </div>
                          )}

                          {item.fundTradingProgramStages[0].maxTrailingLoss && (
                            <div className="token-stage-info">
                              <span className="token-stage-bonus">{item.fundTradingProgramStages[0].maxTrailingLoss} %</span>
                              <span className="token-stage-cap">Max Trailing loss</span>
                            </div>
                          )}

                          <div className="token-stage-info">
                            <span className="token-stage-bonus">{item.fundTradingProgramStages[0].profitTarget} %</span>
                            <span className="token-stage-cap">Phase 1 target</span>
                          </div>

                          {item.fundTradingProgramStages.length > 2 && (
                            <div className="token-stage-info">
                              <span className="token-stage-bonus">{item.fundTradingProgramStages[1].profitTarget} %</span>
                              <span className="token-stage-cap">Phase 2 target</span>
                            </div>
                          )}

                          <div className="token-stage-info">
                            <span className="token-stage-bonus">{item.fundTradingProgramStages[0].minTradingDays}</span>
                            <span className="token-stage-cap">Min days traded</span>
                          </div>

                          <div className="token-stage-info">
                            <span className="token-stage-bonus">
                              <h6>{Number(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h6>
                            </span>
                            <span className="token-stage-cap">One time fee</span>
                          </div>

                          <div className="token-stage-info">
                            <a className="btn btn-md btn-outline btn-auto btn-primary" style={{ margin: "0px 20px" }} href={`/register/${item.fundTradingProgramId}`}>
                              Start
                            </a>
                            {/* <button className="btn btn-primary" onClick={(e) => this.handleButtonStartClick(e, item)}>
                              Start
                            </button> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
