import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Preloader } from "../components/Preloader";

type LayoutProps = {
  children: any;
};

type LayoutState = {};

export class Layout extends React.Component<LayoutProps, LayoutState> {
  private isMobile: boolean;
  private leftSideBarTheme: string = "dark";
  private leftSideBarType: string = "default";

  constructor(props: LayoutProps) {
    super(props);
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  //#region
  toggleMenuCallback() {
    // TODO : Implement me
  }
  // #endregion

  // #region React Events
  componentDidMount() {}
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="nk-wrap">
          <Header toggleMenuCallback={this.toggleMenuCallback} />

          {/* <main className="nk-pages">
            <div className="section"></div>
          </main> */}

          <>{this.props.children}</>

          <Footer />
        </div>
        <Preloader />
      </React.Fragment>
    );
  }
}
