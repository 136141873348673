import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionFundingSpecification } from "../components/home/HomeSectionFundingSpecification";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";

export type PrivacyPolicyPageProps = {};

type PrivacyPolicyPageState = {};

export class PrivacyPolicyPage extends React.Component<PrivacyPolicyPageProps, PrivacyPolicyPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: PrivacyPolicyPageProps, prevState: PrivacyPolicyPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-white" id="solution">
              <div className="container" style={{
                border: "1px solid red",
                borderRadius: "20px",
                padding: "30px 80px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title">Privacy Policy</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>
                <p style={{ fontSize: "18px" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam malesuada lectus in tellus luctus fermentum. Nam in elementum sem, a mattis massa. Vivamus in dui ac ante ultricies condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus ultricies cursus massa, at volutpat nunc tincidunt quis. Etiam faucibus libero a nisi pulvinar sagittis. Aenean non urna in quam pretium scelerisque placerat in lacus. Nam sodales libero ac molestie egestas.
                </p>

                <p style={{ fontSize: "18px" }}>
                  Etiam convallis magna ut enim lacinia, at molestie diam rutrum. Maecenas lobortis ante a semper rhoncus. Etiam feugiat eget quam quis iaculis. Proin pulvinar ipsum quis arcu gravida, vitae luctus urna interdum. Integer ac rutrum tortor. Ut mollis, libero ut volutpat suscipit, est dolor cursus nisl, quis imperdiet nisl felis in risus. Phasellus tellus nunc, feugiat non pharetra quis, aliquet sed nunc. Phasellus non dolor neque. Quisque mollis tortor sapien, at cursus ante efficitur eu. Curabitur vulputate dui molestie arcu lobortis auctor.
                </p>

                <p style={{ fontSize: "18px" }}>
                  Donec dui est, scelerisque at mauris vitae, feugiat vulputate dolor. Ut vestibulum nulla in bibendum cursus. Sed eu dictum diam. Suspendisse eu sollicitudin lectus, sed commodo est. Mauris id tincidunt arcu. Vestibulum nulla nibh, tincidunt sit amet arcu eu, semper scelerisque quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque fringilla ultrices dui at rhoncus. Fusce ut placerat justo, non vestibulum ipsum. Duis commodo lorem porttitor, convallis sapien et, cursus tellus. Donec id lectus nibh. Nulla pulvinar iaculis tortor in semper. Praesent commodo lorem dui, eget eleifend ante bibendum sed. Morbi hendrerit risus a sapien vestibulum vehicula. Sed eleifend varius auctor.
                </p>

                <p style={{ fontSize: "18px" }}>
                  Donec sit amet gravida leo. In quis varius nisi, quis rutrum sem. Maecenas venenatis lorem arcu, sit amet ornare lorem faucibus id. Nulla facilisi. Integer porta ligula non orci mattis blandit. Curabitur molestie rutrum ex, vel sollicitudin ipsum finibus aliquam. Ut tincidunt dui nec libero feugiat, id pulvinar leo rhoncus. Suspendisse nec ipsum sodales justo feugiat convallis eu sit amet augue.
                </p>

                <p style={{ fontSize: "18px" }}>
                  Nam viverra odio eget libero sagittis mollis ut quis eros. Cras semper libero molestie fringilla maximus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin auctor nisi risus, nec sodales sem tincidunt in. Nam eu turpis molestie, luctus sem et, maximus quam. Duis at augue in ligula porttitor cursus et non lacus. Nullam dignissim nisl eu tincidunt viverra. Suspendisse rutrum imperdiet neque, eget rutrum est pulvinar id. Vestibulum dignissim dictum condimentum. Nunc sed ullamcorper orci, sed ultricies quam. Mauris vitae tempus lacus. Pellentesque erat augue, venenatis ac mi fringilla, pulvinar laoreet nunc. Vestibulum id velit interdum lectus feugiat porttitor.
                </p>
              </div>
            </section>


          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
