import React from "react";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";
import { StageType } from "../../lib/drivers/dto/enums/StageType";

export type HomeSectionEvalProcessInfoProps = {
  dataFundTradingProgramListList: FundTradingProgramDto[];
};

type HomeSectionEvalProcessInfoState = {
  phases: number;
};

export class HomeSectionEvalProcessInfo extends React.Component<HomeSectionEvalProcessInfoProps, HomeSectionEvalProcessInfoState> {
  state: HomeSectionEvalProcessInfoState = {
    phases: 1
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionEvalProcessInfoProps, prevState: HomeSectionEvalProcessInfoState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleButtonPhaseClick = async (e: React.FormEvent<HTMLButtonElement>, phases: number) => {
    e.preventDefault();

    await this.setState({ phases });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    const with_1_phase = this.props.dataFundTradingProgramListList.find((ele) => ele.numberStages == 1);
    const stages_1_phase = with_1_phase.fundTradingProgramStages.sort((a, b) => a.sequence - b.sequence);

    const with_2_phases = this.props.dataFundTradingProgramListList.find((ele) => ele.numberStages == 2);
    const stages_2_phases = with_2_phases.fundTradingProgramStages.sort((a, b) => a.sequence - b.sequence);

    const stageData = this.state.phases == 1 ? stages_1_phase : stages_2_phases;

    return (
      <React.Fragment>
        <section className="section bg-white bg-pattern-dots pt-0" id="tokensale">
          <div className="section-tokensale ">
            <div className="container">
              <div className="section-head text-center wide-auto">
                <h2 className="title" style={{ marginTop: "90px" }}>
                  Prop trading evaluation process
                </h2>
                <p>There are two Funded Trader Programs that consist in one or two phases in which the ability will be confirmed.</p>
              </div>
              <div className="row pdb-l">
                <div className="col text-center">
                  <ul className="btn-grp gutter-20px">
                    <li>
                      <button className={`btn ${this.state.phases === 1 ? "btn-primary" : "red"}`} onClick={(e) => this.handleButtonPhaseClick(e, 1)}>
                        1 Phase
                      </button>
                    </li>
                    <li>
                      <button className={`btn ${this.state.phases === 2 ? "btn-primary" : "red"}`} onClick={(e) => this.handleButtonPhaseClick(e, 2)}>
                        2 Phases
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="nk-block nk-block-token mgb-m30">
                <div className="row">
                  {stageData.map((itemStage, idxStage) => (
                    <div className="col" key={idxStage}>
                      <div className="token-info bg-theme tc-light">
                        <h4 className="title title-md mb-2 text-sm-center">
                          {itemStage.type}
                          {itemStage.type == StageType.PHASE ? ` ${idxStage + 1}` : ""}
                        </h4>
                        <table className="table table-s1 table-token">
                          <tbody>
                            <tr>
                              <td className="table-head">Max daily loss</td>
                              <td className="table-des">{itemStage.maxDailyLoss} %</td>
                            </tr>
                            <tr>
                              <td className="table-head">Leverage</td>
                              <td className="table-des">ut to 1:100</td>
                            </tr>
                            {itemStage.maxTrailingLoss && (
                              <tr>
                                <td className="table-head">Max trailing loss</td>
                                <td className="table-des">{itemStage.maxTrailingLoss} %</td>
                              </tr>
                            )}
                            {itemStage.maxTotalLoss && (
                              <tr>
                                <td className="table-head">Max total loss</td>
                                <td className="table-des">{itemStage.maxTotalLoss} %</td>
                              </tr>
                            )}
                            <tr>
                              <td className="table-head">Trading period</td>
                              <td className="table-des">Indefinite</td>
                            </tr>
                            <tr>
                              <td className="table-head">Fee</td>
                              <td className="table-des">{itemStage.type == StageType.LIVE ? "FREE" : "One Time"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}

                  {/* {currentPhaseData.map((phase, index) => (
                    <div className="col" key={index}>
                      <div className="token-info bg-theme">
                        <h4 className="title title-md mb-2 text-sm-center">{phase.title}</h4>
                        <table className="table table-s1 table-token">
                          <tbody>
                            {phase.details.map((detail, idx) => (
                              <tr key={idx}>
                                <td className="table-head">{detail.label}</td>
                                <td className="table-des">{detail.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
