import React from "react";

type InfoItem = {
  icon: string;
  title: string;
  description: string;
};

export type HomeSectionFundingSpecificationProps = {};

type HomeSectionFundingSpecificationState = {
  isLoading: boolean;
};

export class HomeSectionFundingSpecification extends React.Component<HomeSectionFundingSpecificationProps, HomeSectionFundingSpecificationState> {
  state: HomeSectionFundingSpecificationState = {
    isLoading: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: HomeSectionFundingSpecificationProps, prevState: HomeSectionFundingSpecificationState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    const items: InfoItem[] = [];

    items.push({
      icon: "icon icon-lg ikon ikon-cash",
      title: "Get up to $200.000",
      description: "Our funding program grants you up to $200,000 upon successfully passing our evaluation. We cover the losses."
    });
    items.push({
      icon: "icon icon-lg ikon ikon-globe",
      title: "No day limit",
      description: "Take your time while going through the phases, do not pressure yourself."
    });

    items.push({
      icon: "icon icon-lg ikon ikon-paricle",
      title: "No trading restriction",
      description: "Traders are welcome to explore different trading styles and strategies including swing trading, intraday trading, scalping, etc."
    });

    items.push({
      icon: "icon icon-lg ikon ikon-target",
      title: "High leverage",
      description: "We provide leverage of 1:100 to all instruments. Traders can use appropriate lot sizes even while using small stop losses."
    });

    return (
      <React.Fragment>
        <section className="section section-solution bg-theme-dark tc-light" id="solution">
          <div className="">
            <div className="container">
              <div className="section-head text-center wide-auto-sm">
                <h2 className="title">Funding specifications</h2>
                <p >We are the prop firm with the best conditions for the user in the entire market.</p>
              </div>
              <div className="nk-block nk-block-features-s3">
                <div className="row gutter-vr-50px justify-content-center">
                  {(items || []).map((item: InfoItem, key: number) => (
                    <div className="col-lg-6 col-md-10" key={key}>
                      <div className="feature feature-s3 feature-center">
                        <div className="feature-icon feature-icon-lg">
                          <em className={item.icon} />
                        </div>
                        <div className="feature-text">
                          <h4 className="title title-md tc-primary">{item.title}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
