import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { CentralServerEndpointKind } from "./CentralServerClientEnums";
import { ChangePasswordRequestDto } from './dto/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/ChangePasswordResponseDto';
import { CreatePasswordResetDto } from "./dto/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/CreatePasswordResetResponseDto';
import { PasswordResetRequestDto } from "./dto/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/PasswordResetResponseDto';
import { SignInResponseDto } from './dto/SignInResponseDto';
import { SignInRequestDto } from './dto/SignInRequestDto';
import { SignUpRequestDto } from './dto/SignUpRequestDto';
import { SignUpResponseDto } from './dto/SignUpResponseDto';
import { FundTradingProgramDto } from './dto/FundTradingProgramDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Auth Endpoints
    async authSignIn(model: SignInRequestDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authSignUp(model: SignUpRequestDto): Promise<SignUpResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_up.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Profile Endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Fund Trading Program
    async getFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs.toString(), null, null);
            const headers = {};
            
            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto[] = data as FundTradingProgramDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion
    
    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}