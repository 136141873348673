import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Image1 from "../../assets/images/5k.png";
import Image2 from "../../assets/images/10k.png";
import Image3 from "../../assets/images/25k.png";
import Image4 from "../../assets/images/100k.png";
import Image5 from "../../assets/images/200k.png";
import Image6 from "../../assets/images/50k.png";
import Image7 from "../../assets/images/500k.png";
import Image8 from "../../assets/images/1mil.png";
import { FundTradingProgramDto } from "../../lib/drivers/dto/FundTradingProgramDto";

interface GetFundedProps {
  dataFundTradingProgramList: FundTradingProgramDto[];
}

const GetFunded: React.FC<GetFundedProps> = ({ dataFundTradingProgramList }) => {
  const [activePhase, setActivePhase] = useState("phase1");
  const [sliderValue, setSliderValue] = useState<number | number[]>(350000);
  const [changeImage, setChangeImage] = useState<string>(Image8);
  const [changeImageText, setChangeImageText] = useState<string>("Royal Elysian Crown");
  const [selectedProgram, setSelectedProgram] = useState<FundTradingProgramDto | null>(null);

  // Marks for equal spacing
  const marks = {
    5000: "$5k",
    50000: "$10k",
    100000: "$25k",
    150000: "$50k",
    200000: "$100k",
    250000: "$200k",
    300000: "$500k",
    350000: "MILION",
  };

  useEffect(() => {
    // Find the appropriate program based on the slider value and active phase
    const filteredPrograms = dataFundTradingProgramList.filter(program => {
      let accountSize = 0;
      if (sliderValue === 5000) {
        accountSize = 5000;
      } else if (sliderValue === 50000) {
        accountSize = 10000;
      } else if (sliderValue === 100000) {
        accountSize = 25000;
      } else if (sliderValue === 150000) {
        accountSize = 50000;
      } else if (sliderValue === 200000) {
        accountSize = 100000;
      } else if (sliderValue === 250000) {
        accountSize = 200000;
      } else if (sliderValue === 300000) {
        accountSize = 500000;
      } else if (sliderValue === 350000) {
        accountSize = 1000000;
      }

      return (
        Number(program.accountSize) === accountSize &&
        ((activePhase === "phase1" && program.numberStages === 1) ||
          (activePhase === "phase2" && program.numberStages === 2)) // Assuming numberStages indicates phases
      );
    });

    // Set the selected program based on filtered results

    setSelectedProgram(filteredPrograms.length > 0 ? filteredPrograms[0] : null);
  }, [sliderValue, activePhase, dataFundTradingProgramList]);

  const handleSliderChange = (value: number | number[]) => {
    setSliderValue(value);
    switch (value) {
      case 5000:
        setChangeImage(Image1);
        setChangeImageText("Initiate's Helm");
        break;
      case 50000:
        setChangeImage(Image2);
        setChangeImageText("Champion's Crest");
        break;
      case 100000:
        setChangeImage(Image3);
        setChangeImageText("Royal Legacy");
        break;
      case 150000:
        setChangeImage(Image6);
        setChangeImageText("Titans Crown");
        break;
      case 200000:
        setChangeImage(Image4);
        setChangeImageText("Emperor's Glory");
        break;
      case 250000:
        setChangeImage(Image5);
        setChangeImageText("Imperials Shield");
        break;
      case 300000:
        setChangeImage(Image7);
        setChangeImageText("Elysian Crown");
        break;
      case 350000:
        setChangeImage(Image8);
        setChangeImageText("Royal Elysian Crown");
        break;
      default:
        setChangeImage(Image1);
        setChangeImageText("Celestial Diadem");
    }
  };

  return (
    <div>
      <div className="container">
        <div className="py-5 text-center text-white">
          <h1 className="fa-3x fw-bold text-white pb-3">Get funded now</h1>
          <p style={{ color: "#c1a96c" }}>
            Test your skills through our challenges and get up to $1.OOO.OOO of demo funds to trade and gain real profits.
          </p>
        </div>
      </div>

      <div className="phase-toggle-container">
        <div className="d-flex justify-content-center">
          <div className="phase-toggle-btn-group">
            <button
              className={`phase-toggle-btn ${activePhase === "phase1" ? "active" : ""}`}
              onClick={() => setActivePhase("phase1")}
            >
              Phase 1
            </button>
            <button
              className={`phase-toggle-btn ${activePhase === "phase2" ? "active" : ""}`}
              onClick={() => setActivePhase("phase2")}
            >
              Phase 2
            </button>
          </div>
        </div>

        {activePhase === "phase1" && (
          <div className="row">
            <h1 className="fa-3x fw-bold text-white py-5 col-12 text-center">Choose Your Destination</h1>
            <div className="col-12 col-md-7">
              <div className="mb-5 m-md-0 flex-column flex-md-row d-flex justify-content-between align-items-center">
                <div id="account-sizes">
                  <div className="slider-label">Account Price</div>
                  <div className="d-flex gap-2">
                    <button
                      className="currency-btn"
                      style={{
                        background: "#C1A96F",
                        color: "white",
                        border: "2px solid #C1A96F",
                        borderRadius: "10px",
                        padding: "8px 17px",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      ${selectedProgram?.price}
                    </button>
                  </div>
                </div>
                <div className="w-50 me-3">
                  <div className="slider-label">Balance</div>
                  <Slider
                    min={5000}
                    max={350000}
                    marks={marks}
                    step={null}
                    trackStyle={{ height: 8 }}
                    railStyle={{ height: 8 }}
                    value={sliderValue}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <div className="mt-5 overflow-auto">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={1} className="rounded-top"></th>
                      <th>
                        Phase 1
                        <br />
                        The Challenge
                      </th>
                      <th className="rounded-top">
                        Live Phase
                        <br />
                        The Funded Star
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Trading Period</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Min. Trading Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].minTradingDays} Days</td>
                    </tr>
                    <tr>
                      <td>Max. Daily Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].maxDailyLoss} %</td>
                    </tr>
                    <tr>
                      <td>Max. Trailing Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].maxTrailingLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].maxTrailingLoss} %</td>
                    </tr>
                    <tr>
                      <td>Profit Target</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0].profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1].profitTarget || "No Target "} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="phase1-card col-12 col-md-5">
              <div className="text-center">
                <img src={changeImage} className="account-image" alt={changeImageText} />
              </div>
              <div className="text-center">
                <h1 className="mt-3">{changeImageText}</h1>
                <button className="phase-btn active mt-5">START</button>
              </div>
            </div>
          </div>
        )}

        {activePhase === "phase2" && (
          <div className="row">
            <h1 className="fa-3x fw-bold text-white py-5 col-12 text-center">Choose Your Destination</h1>
            <div className="col-12 col-md-7">
              <div className="mb-5 m-md-0 flex-column flex-md-row d-flex justify-content-between align-items-center">
                <div>
                  <div className="slider-label">Account Price</div>
                  <div className="d-flex gap-2">
                    <button
                      className="currency-btn"
                      style={{
                        background: "#C1A96F",
                        color: "white",
                        border: "2px solid #C1A96F",
                        borderRadius: "10px",
                        padding: "8px 17px",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      ${selectedProgram?.price}
                    </button>
                  </div>
                </div>
                <div className="w-50 me-3">
                  <div className="slider-label">Balance</div>
                  <Slider
                    min={5000}
                    max={350000}
                    marks={marks}
                    step={null}
                    trackStyle={{ height: 8 }}
                    railStyle={{ height: 8 }}
                    value={sliderValue}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <div className="mt-5 overflow-auto">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={1} className="rounded-top"></th>
                      <th>
                        Phase 1
                        <br />
                        The Challenge
                      </th>
                      <th className="rounded-top">
                        Phase 1
                        <br />
                        The Challenge 2
                      </th>
                      <th className="rounded-top">
                        Live Phase
                        <br />
                        The Funded Star
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Trading Period</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Min. Trading Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.minTradingDays} Days</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.minTradingDays} Days</td>
                    </tr>
                    <tr>
                      <td>Max. Daily Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.maxDailyLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.maxDailyLoss} %</td>
                    </tr>
                    <tr>
                      <td>Max. Total Loss</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.maxTotalLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.maxTotalLoss} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.maxTotalLoss} %</td>

                    </tr>
                    <tr>
                      <td>Profit Target</td>
                      <td>{selectedProgram?.fundTradingProgramStages[0]?.profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[1]?.profitTarget} %</td>
                      <td>{selectedProgram?.fundTradingProgramStages[2]?.profitTarget || "No Target "} Days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="phase1-card col-12 col-md-5">
              <div className="text-center">
                <img src={changeImage} className="account-image" alt={changeImageText} />
              </div>
              <div className="text-center">
                <h1 className="mt-3">{changeImageText}</h1>
                <button className="phase-btn active mt-5">START</button>
              </div>
            </div>
          </div>
        )}
      </div>

      <style>
        {`
        .account-image{
              width: 17rem !important; 
              object-fit: cover; 
              max-width: 17rem !important; 
              min-height: 20rem !important;
              max-height: 25rem !important;
            }

          @media (max-width: 768px) {
            .slider-label {
                margin-left: -0.5rem;
            }

            .currency-btn {
              margin-left: 1.6rem;
              margin-top: -1.5rem;
              margin-bottom: 2rem;
            }

            .rc-slider {
              right: 6rem !important; 
              width: 25rem !important; 
            }

            .row{
              text-align: center !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GetFunded;
