import React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  search: boolean;
  selectedLanguage: string;
  isDropdownOpen: boolean; // For dropdown visibility
};

// Flags ka object
const flags: Record<string, string> = {
  en: "🇬🇧", // British flag for English
};
// Inline CSS Styles
const styles = {
  button: {
    background: "#C1A96F", // Gradient background
    color: "white",
    border: "none",
    borderRadius: "10px", // Rounded edges
    padding: "8px 17px", // Padding inside button
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center", // Center content vertically
    justifyContent: "center", // Center content horizontally
    transition: "background-color 0.3s ease" // Smooth hover effect
  },
  arrow: {
    marginLeft: "10px" // Spacing between text and arrow
  },
  button2: {
    background: "#0000", // Gradient background
    color: "white",
    border: "2px solid #C1A96F", // Border around the button
    borderRadius: "10px", // Rounded edges
    padding: "8px 17px", // Padding inside button
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center", // Center content vertically
    justifyContent: "center" // Center content horizontally
  }
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: HeaderState = {
    megaMenu: false,
    search: false,
    selectedLanguage: "en", // Initial language state
    isDropdownOpen: false // Initial state for dropdown visibility
  };

  // Language change handler
  handleChangeLanguage = (lang: string) => {
    this.setState({ selectedLanguage: lang, isDropdownOpen: false }); // Update selected language & close dropdown
  };

  // Toggle dropdown visibility
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen // Toggle dropdown state
    }));
  };

  render() {
    const { selectedLanguage, isDropdownOpen } = this.state;

    return (
      <React.Fragment>
        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed" id="header">
          <div className="header-main">
            <div className="header-container container" style={{ maxWidth: "95%" }}>
              <div className="header-wrap header-border">
                <div className="header-logo logo">
                  <a href="./" className="logo-link">
                    <img className="logo-dark" src="/images/bull-logo.png" srcSet="/images/bull-logo.png 2x" alt="logo" style={{
                      height: "73px"
                    }} />
                    <img className="logo-light mobile-view" src="/images/bull-logo.png" srcSet="/images/bull-logo.png 2x" alt="logo" style={{ height: "73px" }} />
                  </a>
                </div>
                <div className="header-nav-toggle">
                  <a href="https://app.bonex.fund" className="btn btn-md btn-auto btn-beige no-change">
                    <span>App</span>
                  </a>
                </div>
                <div className="header-navbar">
                  <nav className="header-menu" id="header-menu">
                    <ul className="menu">
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#">
                          How It Works
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#social-proof">
                          Statistics
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#programs">
                          Programs
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#calculator">
                          Calculator
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#faq">
                          FAQ
                        </a>
                      </li>
                    </ul>
                    <ul className="menu-btns">
                      <li>
                        <div>
                          {/* Language Dropdown */}
                          <div style={{ position: "relative", display: "inline-block" }}>
                            <button
                              onClick={this.toggleDropdown}
                              style={{
                                backgroundColor: "#141C29",
                                color: "white",
                                padding: "8px 17px",
                                fontSize: "16px",
                                borderRadius: "50px",
                                border: "none",
                                cursor: "pointer"
                              }}
                            >
                              <span style={{ marginRight: "10px" }}>{flags[selectedLanguage]}</span> {/* Flag */}
                              {selectedLanguage.toUpperCase()} {/* Language code */}
                            </button>

                            {/* Dropdown Menu */}
                            {isDropdownOpen && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "10px",
                                  backgroundColor: "white",
                                  border: "1px solid #ddd",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  zIndex: 1
                                }}
                              >
                                <button
                                  onClick={() => this.handleChangeLanguage("en")}
                                  style={{
                                    padding: "8px",
                                    display: "block",
                                    width: "100%",
                                    backgroundColor: "black",
                                    border: "none",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    color: "white"
                                  }}
                                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "white")}
                                >
                                  🇬🇧 EN
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <button style={styles.button} onClick={() => window.open("https://app.bonex.fund")}>
                          Get Funded <span style={styles.arrow}>→</span>
                        </button>
                      </li>
                      <li>
                        <button style={styles.button2} onClick={() => window.open("https://app.bonex.fund")}>Login / Register</button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
