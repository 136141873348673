import React from "react";
import { Layout } from "../layout";
import { HomeSectionProgram } from "../components/home/HomeSectionProgram";
import { HomeSectionFundingSpecification } from "../components/home/HomeSectionFundingSpecification";
import { HomeSectionPayoutSystem } from "../components/home/HomeSectionPayoutSystem";
import { HomeSectionCalculator } from "../components/home/HomeSectionCalculator";
import { HomeSectionFaq } from "../components/home/HomeSectionFaq";

export type RefundPolicyPageProps = {};

type RefundPolicyPageState = {};

export class RefundPolicyPage extends React.Component<RefundPolicyPageProps, RefundPolicyPageState> {
  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: RefundPolicyPageProps, prevState: RefundPolicyPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <main className="nk-pages">
            <section className="section section-solution bg-white" id="solution">
              <div className="container" style={{
                border: "1px solid red",
                borderRadius: "20px",
                padding: "30px 80px"
              }}>
                <div className="section-head text-center wide-auto">
                  <h1 className="title">Refund Policy</h1>
                  {/* <p style={{fontSize: "18px"}}>Have questions about getting started, learning the ropes, or earning with us? Find all the answers in our FAQ section.</p> */}

                </div>
                <p style={{ fontSize: "18px" }}>
                  Thank you for choosing BoneX Fund Trading!
                </p>
                <p style={{ fontSize: "18px" }}>
                  If you are not entirely satisfied with your purchase, we are here to help.
                </p>

                <h3>1. Refund Policy</h3>
                <p style={{ fontSize: "18px" }}>
                  <ul>
                    <li>1.1. Refunds are permitted strictly under the following conditions:
                      <ol type="a">
                        <li>a. You may request a withdrawal without providing a reason within fourteen (14) days of your purchase.</li>
                        <li>b. YOU AUTOMATICALLY LOSE YOUR RIGHT TO WITHDRAW FROM THE CONTRACT IF YOU HAVE ALREADY OPENED A POSITION ON YOUR DEMO ACCOUNT BEFORE THE EXPIRY OF THE SPECIFIED TIME LIMIT.</li>
                        <li>c. You will receive your refund via the platform you used for payment within fourteen (14) days.</li>
                      </ol>
                    </li>
                    <li> 1.2. Please note that you have an obligation to make payments carefully and to ensure that you genuinely intend to use our services. We discourage a mere change of mind as a ground for requesting a refund.

                    </li>
                    <li>
                      1.3. We will bear full responsibility for errors that are due to us.
                    </li>
                  </ul>

                </p>

                <h3>2. Notification</h3>
                <p style={{ fontSize: "18px" }}>
                  If you need a refund, please submit your notice of refund to us at the following email: support@bonexfundtrading.com with the subject indicating “Refund”. For a smooth process, we kindly ask you to be concise and include all the information necessary to process your request efficiently and effectively.
                </p>


                <h3>3. Refund during Resolution of Claims</h3>
                <p style={{ fontSize: "18px" }}>
                  You may not be eligible for a refund in respect of any service pending the resolution of a claim for a refund.
                </p>

                <h3>4. Notification for EU Consumers</h3>
                <p style={{ fontSize: "18px" }}>
                  According to Article 16(a) of the Directive 2011/83/EU of the European Parliament and of the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided after the service has been fully performed, if the performance has begun with the consumer’s prior express consent, and with the acknowledgement that they will lose their right of withdrawal once the contract has been fully performed.

                </p>

                <h3>5. Our Discretion</h3>
                <p style={{ fontSize: "18px" }}>
                  Refunds are issued at our discretion and may be accepted or rejected as such.
                </p>

                {/* <h3>6. Contact Us</h3>
                <p style={{ fontSize: "18px" }}>
                  For any questions or comments regarding this Refund Policy, please send an email to support@bonexfundtrading.com.
                </p> */}
              </div>
            </section>


          </main>
        </Layout >
      </React.Fragment >
    );
  }
}
